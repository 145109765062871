import * as React from "react";
import Redirect from "../../../../components/redirect";
//--------------------------------------------------

  const url = "https://www.upbeatmediahk.com/%E7%94%9F%E6%B4%BB/3553/%E8%B7%AF%E9%81%8E%E5%8C%97%E8%A7%92%E9%96%8B%E5%B1%95%E9%87%8D%E7%8F%BE%E4%B8%83%E5%A7%8A%E5%A6%B9%E6%B3%B3%E6%A3%9A";

//--------------------------------------------------
// common config below this line, no need to change
const IndexPage = () => ( <Redirect goto_url={url} /> );
export default IndexPage;
